<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Line Chart
        </b-card-title>
        <b-card-sub-title>Commercial networks</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <!-- <vue-apex-charts
        type="area"
        height="250"
        :options="apexChatData.lineAreaChartSpline.chartOptions"
        :series="apexChatData.lineAreaChartSpline.series"
      /> -->
      {{dataseries}}
      datatanggal:
      {{datatanggal}}
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
// import apexChatData from './apexChartData'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
  },
  props:{
    datatanggal: {
      type: Array,
      default: () => {},
    },
    dataseries: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      // apexChatData,
      rangePicker: ['2019-05-01', '2019-05-10'],
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          // opposite: isRtl
        },
        fill: {
          opacity: 1,
          type: 'solid',
        },
        tooltip: {
          shared: false,
        },
        colors: ['#a4f8cd', '#60f2ca', '#2bdac7'],
      },
    }
  },
  mounted() {
    // this.getdata()
   
      // for (let index = 0; index < data.length; index++) {
      //   this.series.push(data[index])
        
      // }
      // console.log(this.series)
    // this.chartOptions.axis.categories = this.datatanggal
    // console.log(this.chartOptions.axis.categories)
    // var woi = this.dataseries
     console.log('datatanggal')
    // console.log(this.dataseries)
    // var data = this.$props.dataseries
    //   console.log(data)
      // for (let index = 0; index < data.length; index++) {
      //   this.series.push(data[index])
        
      // }
      // console.log(this.series)

    // var kateee = this.chartOptions.xaxis.categories;
      // while (kateee.length) { 
      //     kateee.pop(); 
      // }
    
    var tanggal = this.datatanggal
      console.log(tanggal)

    //   for (let index = 0; index < tanggal.length; index++) {
    //     this.chartOptions.label.push(tanggal[index])
        
    //   }

  },
  created() {
    //  console.log('datatanggal')
    // // console.log(this.dataseries)
    // var data = this.dataseries
    //   console.log(data)
    //   for (let index = 0; index < data.length; index++) {
    //     this.series.push(data[index])
        
    //   }
    //   console.log(this.series)

    // // var kateee = this.chartOptions.xaxis.categories;
    //   // while (kateee.length) { 
    //   //     kateee.pop(); 
    //   // }
    
    // var tanggal = this.datatanggal
    //   console.log(tanggal)

    // //   for (let index = 0; index < tanggal.length; index++) {
    // //     this.chartOptions.label.push(tanggal[index])
        
    // //   }
  },
  methods: {
    // getdata(){
    //   var data = this.dataseries
    //   console.log(data)
    //   for (let index = 0; index < data.length; index++) {
    //     this.series.push(data[index])
        
    //   }
    //   console.log(this.series)

    //   var tanggal = this.datatanggal
    //   console.log(tanggal)

    //   for (let index = 0; index < tanggal.length; index++) {
    //     this.chartOptions.label.push(tanggal[index])
        
    //   }
    //   console.log("untuk push")
    //   console.log(this.chartOptions.label)
    // }
    // gettanggal(){
    //   // for (let index = 0; index < this.datatanggal.length; index++) {
    //   //   console.log('gettanggal')
    //   //   this.chartOptions.axis.categories.push(datatanggal[index])
    //   //   // console.log(this.chartOptions.axis['categories'])
    //   //   // console.log(this.chartOptions.axis.categories)
        
    //   // }
    //   for (let index = 0; index < this.dataseries.length; index++) {
    //     console.log('getdataseries')
    //     this.series.push(dataseries[index])
    //     // console.log(this.chartOptions.axis['categories'])
    //     // console.log(this.chartOptions.axis.categories)
        
    //   }
    //   console.log(this.series)
    // }
  }
}
</script>
