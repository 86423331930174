<template>
  <b-card
    
  >
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title></b-card-title>
      <!-- <div class="text-center">
          <b-card-text class="mb-50">
            Pengaduan Assigned
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ data.Assigned }}</span>
      </div> -->
      <!-- <b-dropdown
        no-caret
        right
        text="Pe"
        variant="transparent"
        class="chart-dropdown"
        toggle-class="p-50"
        size="sm"
      > -->
        <!-- <b-dropdown-item
          v-for="day in data.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item> -->
      <!-- </b-dropdown> -->
    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>
      <b-row>
        <b-col
          sm="2"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ data.Total }}
          </h1>
          <small>Total Pengaduan Masuk</small>
        </b-col>

        <!-- chart -->
        <b-col
          sm="8"
          class="d-flex justify-content-center"
        >

          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptions"
            :series="datachart"
          />
        </b-col>
        <b-col
          sm="2"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ data.Total }}
          </h1>
          <small>Pengaduan Assigned</small>
        </b-col>
        <!--/ chart -->
      </b-row>
      <b-row>
        <b-col
          sm="2"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ data.Total_Hari_Ini }}
          </h1>
          <small>Pengaduan Masuk Hari Ini</small>
        </b-col>

        <!-- chart -->
        <b-col
          sm="8"
          class="d-flex justify-content-center"
        >
        </b-col>
        <b-col
          sm="2"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ data.Unassigned }}
          </h1>
          <small>Pengaduan Unassigned</small>
        </b-col>
        <!--/ chart -->
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    datachart: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      untukresolved:'',
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: [],
          // type: 'string',
        },
      },
    }
  },
  methods: {
    getchart(){
      var data = this.data.Resolved
      console.log("ini woi")
      this.supportTrackerRadialBar.chartOptions.labels.push('Resolved ('+data+')')
      console.log(this.supportTrackerRadialBar.chartOptions.labels)
    }
  },
  mounted() {
    // this.getchart()
  },
  created() {
    this.getchart()
  },
}
</script>
