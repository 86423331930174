<template>
  <section id="dashboard-analytics">
    <!-- <b-row class="match-height">
      <b-col
        lg="6"
        md="12"
      >
        <analytics-congratulation :data="data.congratulations" />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="data.subscribersGained"
          icon="UsersIcon"
          :statistic="kFormatter(data.subscribersGained.analyticsData.subscribers)"
          statistic-title="Subscribers Gained"
          :chart-data="data.subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="data.ordersRecevied"
          icon="PackageIcon"
          color="warning"
          :statistic="kFormatter(data.ordersRecevied.analyticsData.orders)"
          statistic-title="Orders Received"
          :chart-data="data.ordersRecevied.series"
        />
      </b-col>
    </b-row> -->

    <b-row class="match-height">
      <b-col lg="6">
        <b-card
    
  >
          <b-card-header class="pb-0">
            <b-card-title></b-card-title>
          </b-card-header>
          <!--/ title and dropdown -->

          <b-card-body>
            <b-row>
              <b-col
                sm="2"
                class="d-flex flex-column flex-wrap text-center"
              >
                <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
                  {{ chart1.Total }}
                </h1>
                <small>Total Pengaduan Masuk</small>
              </b-col>

              <!-- chart -->
              <b-col
                sm="8"
                class="d-flex justify-content-center"
              >

                <!-- apex chart -->
                <vue-apex-charts
                  type="radialBar"
                  height="270"
                  :options="supportTrackerRadialBar.chartOptions"
                  :series="datachart"
                />
              </b-col>
              <b-col
                sm="2"
                class="d-flex flex-column flex-wrap text-center"
              >
                <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
                  {{ chart1.Total }}
                </h1>
                <small>Pengaduan Assigned</small>
              </b-col>
              <!--/ chart -->
            </b-row>
            <b-row>
              <b-col
                sm="2"
                class="d-flex flex-column flex-wrap text-center"
              >
                <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
                  {{ chart1.Total_Hari_Ini }}
                </h1>
                <small>Pengaduan Masuk Hari Ini</small>
              </b-col>

              <!-- chart -->
              <b-col
                sm="8"
                class="d-flex justify-content-center"
              >
              </b-col>
              <b-col v-if="pengguna.hak_akses != 'expert'"
                sm="2"
                class="d-flex flex-column flex-wrap text-center"
              >
                <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
                  {{ chart1.Unassigned }}
                </h1>
                <small>Pengaduan Unassigned</small>
              </b-col>
              <!--/ chart -->
            </b-row>
          </b-card-body>
        </b-card>
        <!-- <analytics-support-tracker :datachart="datachart" :data="datadashboard.Gauge_Meter" /> -->
      </b-col>
      <b-col lg="6">
        <!-- {{data.supportTracker}} -->
        <!-- <apex-line-chart :dataseries="dataseries" :datatanggal="datatanggal" /> -->
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <!-- <b-card-title class="mb-1">
                Line Chart
              </b-card-title>
              <b-card-sub-title>Commercial networks</b-card-sub-title> -->
            </div>
            <!--/ title and subtitle -->

            <!-- datepicker -->
            <!-- <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              <flat-pickr
                v-model="rangePicker"
                :config="{ mode: 'range'}"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
              />
            </div> -->
            <!-- datepicker -->
          </b-card-header>

          <b-card-body>
            <vue-apex-charts
              type="area"
              height="350"
              :options="chartOptions"
              :series="series"
            />
            <!-- {{dataseries}} -->
            <!-- datatanggal:
            {{datatanggal}}
            <br>

            ini data categoriesnya:
            {{chartOptions.xaxis.categories}} -->
          </b-card-body>
        </b-card>
      </b-col>
     
      <b-col
        lg="4"
        md="12"
      >
        <b-card
          text-variant="left"
          bg-variant="success"
        >
          <!-- images -->
          <!-- <b-img
            :src="require('@/assets/images/elements/decore-left.png')"
            class="congratulations-img-left"
          />
          -->
          
          <!--/ images -->

          <b-avatar
            variant="default"
            size="70"
            class="shadow mb-2"
          >
            <feather-icon
            style="color:white"
              size="28"
              icon="PhoneIcon"
            />
          </b-avatar>
          <!-- <b-img
            :src="require('@/assets/images/elements/decore-right.png')"
            class="congratulations-img-right"
          />  -->
          <h1 class="mb-1 mt-50 text-white">
            WhatsApp
          </h1>
          <b-card-text class="m-auto w-55 text-white">
            +62 811-1287-882
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        lg="4"
        md="12"
      >
        <b-card
          text-variant="left"
          class="card card-congratulations"
        >
          <!-- images -->
          <!-- <b-img
            :src="require('@/assets/images/elements/decore-left.png')"
            class="congratulations-img-left"
          />
          <b-img
            :src="require('@/assets/images/elements/decore-right.png')"
            class="congratulations-img-right"
          /> -->
          <!--/ images -->

          <b-avatar
            variant="primary"
            size="70"
            class="shadow mb-2"
          >
            <feather-icon
              size="28"
              icon="SendIcon"
            />
          </b-avatar>
          <h1 class="mb-1 mt-50 text-white">
            Telegram
          </h1>
          <b-card-text class="m-auto w-55">
            CegatBot
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        lg="4"
        md="12"
      >
        <b-card
          text-variant="left"
          bg-variant="danger"
        >
          <!-- images -->
          <!-- <b-img
            :src="require('@/assets/images/elements/decore-left.png')"
            class="congratulations-img-left"
          />
          <b-img
            :src="require('@/assets/images/elements/decore-right.png')"
            class="congratulations-img-right"
          /> -->
          <!--/ images -->

          <b-avatar
            variant="white"
            size="70"
            class="shadow mb-2"
          >
            <feather-icon
            style="color:white"
              size="28"
              icon="MailIcon"
            />
          </b-avatar>
          <h1 class="mb-1 mt-50 text-white">
            Email
          </h1>
          <b-card-text class="m-auto w-55 text-white">
            cegat@progojaya.id
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-row class="match-height">
      <b-col lg="12">
         <apex-line-chart />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,BImg } from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
import AnalyticsTimeline from './AnalyticsTimeline.vue'
import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'
import AnalyticsAppDesign from './AnalyticsAppDesign.vue'
import ApexLineChart from './ApexLineChart.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios';
import VueApexCharts from 'vue-apexcharts'
import Swal from 'sweetalert2'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BImg,
    VueApexCharts,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    AnalyticsCongratulation,
    AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    AnalyticsSalesRadarChart,
    AnalyticsAppDesign,
    InvoiceList,
    ApexLineChart
  },
  data() {
    return {
      data: {},
      chart1:[],
      url: localStorage.getItem('baseapi'),
      pengguna: JSON.parse(localStorage.getItem('userData')),
      datadashboard:[],
      datachart:[],
      dataseries:[],
      datatanggal:[],
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          type: 'datetime',
          // categories: ["2022-03-01", "2022-03-02", "2022-03-03", "2022-03-04", "2022-03-05", "2022-03-06", "2022-03-07"],
          categories:[]
        },
        yaxis: {
          // opposite: isRtl
        },
        fill: {
          opacity: 1,
          type: 'solid',
        },
        tooltip: {
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            
            return '<div>' + '<span> Pesan '+
            w.globals.initialSeries[seriesIndex].name+' sampai hari ini : ' + data + '</span>'+
            
            '</div>';
          }
        },
        // tooltip: {
        //   shared: false,
        //   // tooltip: {
        //   //   x: {
        //   //     format: 'dd MMM',
        //   //     formatter: undefined,
        //   //   },
        //   //   y: {
        //   //     formatter: (value) => { return value + "%" },
        //   //   },              
        //   // }
        // },
        colors: ['#a4f8cd', '#60f2ca', '#2bdac7'],
      },
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: [],
          // type: 'string',
        },
      },
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })
  },
  methods: {
    kFormatter,
    loaddata(){
      if (this.pengguna.hak_akses == 'expert') {
        var api ='dashboard_expert?expert='+this.pengguna.nama;
      } else {
        var api ='dashboard_su';
      }
      axios.get(this.url+api, {
      headers: {
          "Content-type": "application/json",
          "xth": this.pengguna.token,
          }
      })
      .then(response => {
        this.datadashboard = response.data.data
        this.chart1 = response.data.data.Gauge_Meter
        if (this.pengguna.hak_akses == 'expert') {
          this.supportTrackerRadialBar.chartOptions.labels.push('Resolved')
        } else {
          this.supportTrackerRadialBar.chartOptions.labels.push('Resolved ('+this.chart1.Resolved+')')
        }
        console.log(this.supportTrackerRadialBar.chartOptions.labels)
        var chartradial = response.data.data.Gauge_Meter
        this.datachart.push(response.data.data.Gauge_Meter.Resolved_Percent)
        console.log(this.datachart)
        var chartline=response.data.data.Line
        // console.log(chartline)
        var test = []
        var test2 = []
        var jadi=[]
        var obj1=[]
        var obj2=[]
        var tanggal = []
        var baru1 = ""
        var baru2 = ""
        for (var prop in chartline) {
          // series.push({name : prop,  })
          if (prop === "Masuk") {
            baru1 = {name: prop, data: test}
            // console.log(baru1)
            obj1.push({name: prop, data: test})
            for (var woi in chartline[prop]) {
              test.push(chartline[prop][woi])
            }
            test.reverse()
          } else {
            baru2 = {name: prop, data: test2}
            obj2.push({name: prop, data: test2})
            for (var woi in chartline[prop]) {
                      tanggal.push(woi)
                      test2.push(chartline[prop][woi])
                    }
                    test2.reverse()
                    console.log("data2")
              console.log(test2)
          }
        }
        jadi.push(baru1,baru2)
        this.dataseries = jadi
        this.datatanggal = tanggal
        
        for (let index = 0; index < this.dataseries.length; index++) {
          this.series.push(this.dataseries[index])
          
        }
  
        for (var index = 0; index < tanggal.length; index++) {
          this.chartOptions.xaxis.categories.push(tanggal[index])
          
        }
        // this.chartOptions.xaxis.categories = this.datatanggal
        this.chartOptions.xaxis.categories.reverse()
        
        console.log(this.chartOptions.xaxis.categories)
      })
      .catch(error => {
        if (error.response.data === 'Token Tidak Valid/Hak akses tidak sesuai') {
          this.$swal({
            title: error.response.data,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
            }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem('userData')
              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            }
          })
          // localStorage.removeItem('userData')
          // // Redirect to login page
          // this.$router.push({ name: 'auth-login' })
        } else {
         this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: error.response.data,
            variant: 'danger',
          },
        })
        }
      })
    },
  },
  mounted() {
      this.loaddata()
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>